
  import { mapActions } from 'vuex'
  import IconShare from '@loavies/component-library/dist/icons/icon-share'
  import IconEye from '@loavies/component-library/dist/icons/icon-eye'
  import IconDiscount from '@loavies/component-library/dist/icons/icon-discount-borderless'
  import IconLabelVariant from '@loavies/component-library/dist/icons/icon-label-variant-bordeless'
  import IconArrowUpBorderless from '@loavies/component-library/dist/icons/icon-arrow-up-borderless'
  import IconEditMinimal from '@loavies/component-library/dist/icons/icon-edit-minimal-borderless'

  import {
    LISTING,
    MY_LISTING,
  } from '~/lib/constants/routes'
  import { PLATFORM_TITLE } from '~/lib/constants/default'
  import {
    APP_MODAL_NAMES,
    APP_TRAY_NAMES,
    OVERLAYS,
  } from '~/lib/constants/overlays'

  export default {
    components: {
      IconShare,
      IconEye,
      IconDiscount,
      IconLabelVariant,
      IconArrowUpBorderless,
      IconEditMinimal,
    },
    props: {
      listing: {
        type: Object,
        required: true,
      },
    },
    emits: ['close', 'share'],
    computed: {
      discount() {
        return this.listing.publicData?.discount?.discountPercentage
      },
      labelOptions() {
        return this.$staticDataService.listingLabels
      },
      defaultLabelId() {
        const defaultLabel = this.labelOptions.find(label => label.isDefault)
        return defaultLabel ? defaultLabel.id : ''
      },
      label() {
        if (this.listing.publicData?.labelId === this.defaultLabelId) return

        return this.listing.publicData?.labelId
      },
    },
    methods: {
      ...mapActions({
        showToastMessage: 'toast/showToastMessage',
        setCurrentOverlay: 'overlay/setCurrentOverlay',
      }),
      openLabelOptions() {
        this.$emit('close')
        this.setCurrentOverlay({
          settings: {
            options: {
              listing: this.listing,
            },
          },
          desktop: {
            type: OVERLAYS.APP_MODAL,
            component: APP_MODAL_NAMES.LISTING_LABEL_APP_MODAL,
          },
          mobile: {
            type: OVERLAYS.APP_TRAY,
            component: APP_TRAY_NAMES.LISTING_LABEL_APP_TRAY,
          },
        })
      },
      openDiscountOptions() {
        this.$emit('close')
        this.setCurrentOverlay({
          settings: {
            options: {
              listing: this.listing,
            },
          },
          desktop: {
            type: OVERLAYS.APP_MODAL,
            component: APP_MODAL_NAMES.LISTING_DISCOUNT_APP_MODAL,
          },
          mobile: {
            type: OVERLAYS.APP_TRAY,
            component: APP_TRAY_NAMES.LISTING_DISCOUNT_APP_TRAY,
          },
        })
      },
      toListingEditor() {
        this.$router.push(this.localePath(`${MY_LISTING}/${this.listing.id}/edit`))
        this.$emit('close')
      },
      toListingDetail() {
        this.$router.push(this.localePath(`${MY_LISTING}/${this.listing.id}`))
        this.$emit('close')
      },
      async sortToTop() {
        this.$emit('close')

        try {
          await this.$listingHandlerService.updateShopIndex({
            id: this.listing.id,
            shopIndex: new Date().getTime(),
          })
        } catch {
          this.showToastMessage({
            text: this.$t('general_error'),
            icon: 'IconClose',
          })
        }
      },
      async share() {
        try {
          this.$emit('share')

          const url = `${window.location.origin}${LISTING}/${this.listing.id}`

          if (navigator.share) {
            const shareData = {
              title: `${PLATFORM_TITLE} - ${this.listing.title}`,
              text: this.listing.description,
              url,
            }

            return navigator
              .share(shareData)
              .catch(() => {})
          }

          if (navigator.clipboard) {
            await navigator.clipboard.writeText(url)

            return this.showToastMessage({
              text: this.$t('share_url_copied'),
              icon: 'IconCheckmark',
            })
          }

          return this.showToastMessage({
            text: this.$t('share_not_supported'),
            icon: 'IconClose',
          })
        } catch {
          return this.showToastMessage({
            text: this.$t('something_went_wrong'),
            icon: 'IconClose',
          })
        }
      },
    },
  }
