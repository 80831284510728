
  import { ListingModel } from '@loavies/c2c-models'

  import IconSortBorderless from '@loavies/component-library/dist/icons/icon-sort-borderless'

  export default {
    components: {
      IconSortBorderless,
    },
    props: {
      listing: {
        type: ListingModel,
        required: true,
      },
      showMenu: {
        type: Boolean,
        default: false,
      },
      draggable: {
        type: Boolean,
        default: false,
      },
    },
  }
