
  import { mapActions, mapState } from 'vuex'
  import { ListingModel } from '@loavies/c2c-models'
  import AppButton from '@loavies/component-library/dist/app-button'
  import AppPopover from '@loavies/component-library/dist/app-popover'
  import IconMoreHorizontal from '@loavies/component-library/dist/icons/icon-more-horizontal'

  import {
    APP_TRAY_NAMES,
    OVERLAYS,
  } from '~/lib/constants/overlays'

  export default {
    components: {
      AppButton,
      AppPopover,
      IconMoreHorizontal,
    },
    props: {
      listing: {
        type: ListingModel,
        required: true,
      },
    },
    computed: {
      ...mapState({
        isMobile: state => state.isMobile,
      }),
    },
    methods: {
      ...mapActions({
        setCurrentOverlay: 'overlay/setCurrentOverlay',
      }),
      openAccountListingMoreMenuAppTray() {
        this.setCurrentOverlay({
          settings: {
            type: OVERLAYS.APP_TRAY,
            component: APP_TRAY_NAMES.LISTING_MORE_MENU_APP_TRAY,
            options: {
              listing: this.listing,
            },
          },
        })
      },
    },
  }
