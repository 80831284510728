
  import { mapActions, mapGetters } from 'vuex'
  import {
    LISTING_STATES,
    TRANSACTION_STATES,
    TransactionModel,
    ListingModel,
  } from '@loavies/c2c-models'
  import ProductCard from '@loavies/component-library/dist/product-card'
  import AppImage from '@loavies/component-library/dist/app-image'

  import { LISTING, SHOP } from '~/lib/constants/routes'
  import { FALLBACK_AVATAR_IMAGE } from '~/lib/constants/user'

  export default {
    components: {
      ProductCard,
      AppImage,
    },
    props: {
      listing: {
        type: ListingModel,
        required: true,
      },
      redirectToAccountListing: {
        type: Boolean,
        default: false,
      },
      showAuthor: {
        type: Boolean,
        default: false,
      },
      transaction: {
        type: TransactionModel,
        default: null,
      },
      showTransactionState: {
        type: Boolean,
        default: false,
      },
      showMenu: {
        type: Boolean,
        default: false,
      },
      draggable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        wishlistCount: this.listing.publicData.wishlistCount,
      }
    },
    computed: {
      ...mapGetters({
        getListingIsInWishlist: 'wishlist/getListingIsInWishlist',
        getListingIsActiveByListingId: 'transactions/getListingIsActiveByListingId',
      }),
      isLink() {
        return !this.showMenu && !this.draggable
      },
      labelOptions() {
        return this.$staticDataService.listingLabels
      },
      label() {
        const selectedLabelId = this.listing.publicData?.labelId
        return this.labelOptions.find(label => label.id === selectedLabelId) ?? null
      },
      labelBackgroundColor() {
        return this.label?.backgroundColor
      },
      labelText() {
        return this.label?.text
      },
      labelDarkText() {
        return this.label?.darkText
      },
      discountLabelText() {
        return this.discount?.discountPercentage
          ? `-${this.discount?.discountPercentage}%`
          : ''
      },
      firstImage() {
        return this.listing.images?.[0]
      },
      imageUrl() {
        return this.firstImage?.variants.w600.url || null
      },
      imageWidth() {
        return this.firstImage?.variants.w600.width || null
      },
      price() {
        return this.$currencyService.formatCentsToLocalePrice(this.listing.price.amount)
      },
      originalPrice() {
        if (!this.discount?.discountPercentage) return

        return this.$currencyService.formatCentsToLocalePrice(
          this.discount.originalPrice.amount,
          this.discount.originalPrice.currency
        )
      },
      authorImage() {
        return this.listing.author.image?.variants.w200.url || FALLBACK_AVATAR_IMAGE
      },
      authorName() {
        return this.listing.author.displayName
      },
      authorStoreName() {
        return this.$userHelperService.getFormattedStoreName(this.listing.author)
      },
      authorStoreLink() {
        return this.localePath(`${SHOP}/${this.listing.author.id}`)
      },
      listingIsInWishlist() {
        return this.getListingIsInWishlist(this.listing.id)
      },
      discount() {
        return this.listing.publicData?.discount ?? null
      },
      link() {
        if (!this.redirectToAccountListing) {
          return this.localePath(`${LISTING}/${this.listing.id}`)
        }

        if (this.isDraft) {
          return this.localePath(`/account/listing/${this.listing.id}/edit`)
        }

        return this.localePath(`/account/listing/${this.listing.id}`)
      },
      listingIsActive() {
        return this.getListingIsActiveByListingId(this.listing.id)
      },
      transactionStateTranslation() {
        if (this.listingIsActive) {
          return this.$t('listing_active')
        }

        if ([TRANSACTION_STATES.COMPLETED, TRANSACTION_STATES.COMPLETED_AND_REVIEWED].includes(this.transaction?.state)) {
          return this.$transactionHelperService.getTransactionStateTranslation(TRANSACTION_STATES.COMPLETED)
        }

        return this.$transactionHelperService.getTransactionStateTranslation(this.transaction?.state)
      },
      transactionStateHasError() {
        return [TRANSACTION_STATES.DISPUTE, TRANSACTION_STATES.NOT_DELIVERED].includes(this.transaction?.state)
      },
      transactionStateIsFinished() {
        return this.$transactionHelperService.transactionStateIsFinished(this.transaction)
      },
      isDraft() {
        return this.listing.state === LISTING_STATES.DRAFT
      },
      subtitle() {
        const listingDescription = this.listing.description ? ' - ' + this.listing.description : ''
        const listingSize = this.listing.publicData.size

        return `${this.$t('listing_size')} ${listingSize}${listingDescription}`
      },
    },
    methods: {
      ...mapActions({
        addToWishlist: 'wishlist/addToWishlist',
        removeFromWishlist: 'wishlist/removeFromWishlist',
      }),
      async toggleWishlist() {
        if (this.showTransactionState || this.showMenu || this.draggable) return

        if (this.listingIsInWishlist) {
          this.wishlistCount -= 1
          await this.removeFromWishlist(this.listing)
        } else {
          this.wishlistCount += 1
          await this.addToWishlist(this.listing)
        }
      },
    },
  }
